import React, { forwardRef, useContext, useImperativeHandle, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { buildAddressString } from '../../../../utils/location-methods';
import { Button, Card, DetailedCheckbox, IconButton, Map, RangeInput, Select, TextArea, TextInput, Typography } from '../../../../components';
import * as S from './LocationCard.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import { LOCATION_TYPES, ROLES, STATE_OPTIONS } from '../../../../../constants/general.constants';
import { DirectoryResourceDetailsContext } from '../../DirectoryResourceDetails';
import { formatPayloadBeforeUpdate } from '../OverviewTab';
import { createUpdateResource } from '../../../../services/resources';
import { fetchBusinessLocations } from '../../../../services/businessLocation';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { required } from '../../../../utils/form-default-errors';
import { errorHandler } from '../../../../services/authService';
import { fetchBusinessProfile } from '../../../../services/directory';
import toast from 'react-hot-toast';

const LOCATION_TYPES_WITHOUT_IN_PERSON = LOCATION_TYPES.filter(({ id }) => id !== 'inperson');
const LOCATION_TYPES_WITHOUT_STUDENT_AND_WORKSITE = LOCATION_TYPES.filter(({ id }) => id !== 'student' && id !== 'worksite');

const LocationEditModal = forwardRef((_, ref) => {
	const {
		state: { resource },
		dispatch,
	} = useContext(DirectoryResourceDetailsContext);
	const [isOpen, setIsOpen] = useState(false);

	const {
		handleSubmit,
		control,
		setValue,
		reset,
		watch,
		getValues,
		register,
		formState: { errors, isSubmitting },
	} = useForm();

	const user = useSelector((state) => state.user.value);
	const [locationsLoading, setLocationsLoading] = useState('idle');
	const [businessProfile, setBusinessProfile] = useState(undefined);

	const locationTypes = watch('locationTypes');

	const { fields, append, remove } = useFieldArray({ control, name: 'worksiteLocations' });

	const handleAddLocation = () => {
		append({});
	};

	const handleRemoveLocation = (index) => {
		remove(index);
	};

	async function initializeData() {
		setLocationsLoading('loading');
		try {
			if (user.role === ROLES.ADMIN || user.role === ROLES.SUPER) {
				const {
					data: { result },
				} = await fetchBusinessLocations();
				setBusinessProfile({ locations: result.locations });
			} else {
				const {
					data: { result },
				} = await fetchBusinessProfile({ businessId: user?.business?.id });
				setBusinessProfile(result.business);
			}
			setLocationsLoading('success');
		} catch (error) {
			setLocationsLoading('error');
		}
	}

	const handleOpen = () => {
		initializeData();
		setValue('locationTypes', resource?.locationTypes);
		setValue('virtualLocationNotes', resource?.virtualLocationNotes);
		setValue(
			'worksiteLocations',
			resource?.worksiteLocations.map((item) => {
				return {
					locationId: item.location.id,
					notes: item.notes,
				};
			})
		);
		setValue('studentMaxTravelDistance', resource?.studentMaxTravelDistance);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const { virtualLocationNotes, worksiteLocations, studentMaxTravelDistance } = data;

		if (data?.locationTypes?.includes('worksite') && worksiteLocations?.length === 0) {
			toast.error('Please add at least one worksite location');
			return;
		}

		const newValues = {
			locationTypes: data?.locationTypes,
			virtualLocationNotes: data?.locationTypes?.includes('virtual') ? virtualLocationNotes : null,
			worksiteLocations: data?.locationTypes?.includes('worksite') ? worksiteLocations : [],
			studentMaxTravelDistance: data?.locationTypes?.includes('student') ? studentMaxTravelDistance : null,
		};
		const newResource = formatPayloadBeforeUpdate(resource, { ...newValues });

		const {
			data: { result },
		} = await createUpdateResource(newResource);
		dispatch({ type: 'SET_RESOURCE', payload: result.resource });
		handleClose();
	}

	const onSubmit = (e) => handleSubmit(asyncCaller)(e).catch(errorHandler);

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				<S.InfoWrapper>
					<Typography tag="h2" weight="extrablack" center>
						Edit Locations
					</Typography>
					<Typography tag="p" center>
						Please edit the locations below to update the resource.
					</Typography>
				</S.InfoWrapper>
				<S.FieldsWrapper>
					<div>
						<Typography tag="h3" weight="bold">
							Location Type
						</Typography>
						<Typography tag="p" variation="2">
							Select all locations you want to have this resource.
						</Typography>
					</div>
					<S.LocationStepWrapper>
						{LOCATION_TYPES_WITHOUT_IN_PERSON.map(({ id, name, description, icon }) => (
							<DetailedCheckbox key={id} id={id} value={id} title={name} description={description} icon={icon} {...register('locationTypes', { required: required('Location Type') })} />
						))}
						{!!errors.locationTypes && (
							<div className="error-wrapper">
								<Typography tag="p" variation="2">
									{errors.locationTypes.message}
								</Typography>
							</div>
						)}
					</S.LocationStepWrapper>
					<S.SelectedLocations>
						<div>
							<Typography tag="h3" weight="bold">
								Location Details
							</Typography>
							<Typography tag="p" variation="2">
								Enter in more information about your locations.
							</Typography>
						</div>
						{locationTypes?.length === 0 && (
							<Typography tag="p" className="gray-color">
								No location types selected.
							</Typography>
						)}
						{!locationTypes ||
							(locationTypes.length >= 1 && (
								<>
									{locationTypes?.includes('virtual') && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'camera']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													Virtual
												</Typography>
												<TextArea id="virtualLocationNotes" label="Location Notes" placeholder="Write a message..." error={errors?.virtualLocationNotes} {...register('virtualLocationNotes')} />
											</div>
										</div>
									)}
									{locationTypes?.includes('worksite') && locationsLoading === 'success' && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'building']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													Worksite Location
												</Typography>
												{fields.map(({ id }, index) => (
													<S.LocationItem key={id}>
														<Typography tag="h5" weight="bold">
															Location {index + 1}
														</Typography>
														<S.SmallFieldsWrapper>
															<Controller
																name={`worksiteLocations.${index}.locationId`}
																control={control}
																rules={{ required: required('Location') }}
																render={({ field: { onChange, value } }) => (
																	<Select
																		getOptionLabel={(option) => option.name}
																		getOptionValue={(option) => option.id}
																		label="Location"
																		id={`location-${index}`}
																		error={errors?.worksiteLocations?.[index]?.locationId}
																		options={businessProfile?.locations}
																		value={businessProfile?.locations.find((c) => c.id === value) || ''}
																		onChange={(val) => onChange(val.id)}
																	/>
																)}
															/>
															{getValues(`worksiteLocations.${index}.locationId`) && (
																<div className="step__information-card">
																	<div className="icon-wrapper">
																		<FontAwesomeIcon icon="school" />
																	</div>
																	<div className="info-wrapper">
																		<Typography tag="h5" weight="bold">
																			{businessProfile?.locations.find((location) => location.id === getValues(`worksiteLocations.${index}.locationId`))?.name}
																		</Typography>
																		<div className="info-line">
																			<FontAwesomeIcon icon={['fal', 'map-marker']} />
																			<Typography tag="p" variation="2">
																				{`${businessProfile?.locations.find((location) => location.id === getValues(`worksiteLocations.${index}.locationId`))?.address?.addressLine1}, ${
																					businessProfile?.locations.find((location) => location.id === getValues(`worksiteLocations.${index}.locationId`))?.address?.state
																				} ${businessProfile?.locations.find((location) => location.id === getValues(`worksiteLocations.${index}.locationId`))?.address?.postalCode}`}
																			</Typography>
																		</div>
																		<div className="info-line">
																			<FontAwesomeIcon icon={['fal', 'landmark']} />
																			<Typography tag="p" variation="2">
																				{businessProfile?.locations.find((location) => location.id === getValues(`worksiteLocations.${index}.locationId`))?.address?.city}
																			</Typography>
																		</div>
																		<div className="info-line">
																			<FontAwesomeIcon icon={['fal', 'school']} />
																			<Typography tag="p" variation="2">
																				{businessProfile?.locations.find((location) => location.id === getValues(`worksiteLocations.${index}.locationId`))?.searchTag}
																			</Typography>
																		</div>
																	</div>
																</div>
															)}
															<TextArea
																label="Location Notes"
																id={`location-notes-${index}`}
																error={errors?.worksiteLocations?.[index]?.notes}
																{...register(`worksiteLocations.${index}.notes`, {
																	required: required('Location Notes'),
																})}
															/>
														</S.SmallFieldsWrapper>
														{fields.length > 1 && (
															<Button className="location-remove" variant="text" type="button" onClick={() => handleRemoveLocation(index)}>
																<Typography variation="button-medium" weight="bold">
																	Remove
																</Typography>
															</Button>
														)}
													</S.LocationItem>
												))}
												<S.AddWrapper>
													<Button variant="text" type="button" onClick={handleAddLocation}>
														<FontAwesomeIcon icon={['fal', 'plus']} />
														<Typography variation="button-medium" weight="bold">
															Add Location
														</Typography>
													</Button>
												</S.AddWrapper>
											</div>
										</div>
									)}
									{locationTypes?.includes('student') && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'users']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													Student Location
												</Typography>
												<Controller
													name="studentMaxTravelDistance"
													control={control}
													rules={{ required: required('Maximum Travel Distance') }}
													render={({ field: { onChange, value } }) => <RangeInput error={errors?.studentMaxTravelDistance} label="Maximum Travel Distance" id="student-max-travel-distance" unit="mi" value={value} onChange={onChange} />}
												/>
											</div>
										</div>
									)}
								</>
							))}
					</S.SelectedLocations>
				</S.FieldsWrapper>
				<S.ButtonsWrapper>
					<Button type="submit">
						<Typography weight="bold" variation="button-medium">
							{isSubmitting ? 'Loading' : 'Save'}
						</Typography>
					</Button>
				</S.ButtonsWrapper>
			</S.Form>
		</ReactModal>
	);
});

LocationEditModal.displayName = 'LocationEditModal';

const LocationAltEditModal = forwardRef((_, ref) => {
	const {
		state: { resource },
		dispatch,
	} = useContext(DirectoryResourceDetailsContext);
	const [isOpen, setIsOpen] = useState(false);

	const {
		handleSubmit,
		control,
		setValue,
		reset,
		watch,
		register,
		formState: { errors, isSubmitting },
	} = useForm();

	const locationTypes = watch('locationTypes');

	const handleOpen = () => {
		setValue('locationTypes', resource?.locationTypes);
		setValue('virtualLocationNotes', resource?.virtualLocationNotes);
		setValue('inPersonLocationAddress', resource?.inPersonLocationAddress);
		setValue('inPersonNotes', resource?.inPersonLocationNotes);
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		setTimeout(() => {
			reset();
		}, 200);
	};

	async function asyncCaller(data) {
		const { virtualLocationNotes, inPersonLocationAddress, inPersonNotes } = data;

		const newValues = {
			locationTypes: data?.locationTypes,
			virtualLocationNotes: data?.locationTypes?.includes('virtual') ? virtualLocationNotes : null,
			inPersonLocationAddress: data?.locationTypes?.includes('inperson') ? inPersonLocationAddress : null,
			inPersonNotes: data?.locationTypes?.includes('inperson') ? inPersonNotes : null,
		};
		const newResource = formatPayloadBeforeUpdate(resource, { ...newValues });

		const {
			data: { result },
		} = await createUpdateResource(newResource);
		dispatch({ type: 'SET_RESOURCE', payload: result.resource });
		handleClose();
	}

	const onSubmit = (e) => handleSubmit(asyncCaller)(e).catch(errorHandler);

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose,
	}));

	return (
		<ReactModal ref={ref} closeTimeoutMS={200} isOpen={isOpen} onRequestClose={handleClose}>
			<S.Form onSubmit={onSubmit}>
				<IconButton className="close-button" type="button" icon={['fal', 'times']} onClick={handleClose} />
				<S.InfoWrapper>
					<Typography tag="h2" weight="extrablack" center>
						Edit Locations
					</Typography>
					<Typography tag="p" center>
						Please edit the locations below to update the resource.
					</Typography>
				</S.InfoWrapper>
				<S.FieldsWrapper>
					<div>
						<Typography tag="h3" weight="bold">
							Location Type
						</Typography>
						<Typography tag="p" variation="2">
							Select all locations you want to have this resource.
						</Typography>
					</div>
					<S.LocationStepWrapper>
						{LOCATION_TYPES_WITHOUT_STUDENT_AND_WORKSITE.map(({ id, name, description, icon }) => (
							<DetailedCheckbox key={id} id={id} value={id} title={name} description={description} icon={icon} {...register('locationTypes', { required: required('Location Type') })} />
						))}
						{!!errors.locationTypes && (
							<div className="error-wrapper">
								<Typography tag="p" variation="2">
									{errors.locationTypes.message}
								</Typography>
							</div>
						)}
					</S.LocationStepWrapper>
					<S.SelectedLocations>
						<div>
							<Typography tag="h3" weight="bold">
								Location Details
							</Typography>
							<Typography tag="p" variation="2">
								Enter in more information about your locations.
							</Typography>
						</div>
						{locationTypes?.length === 0 && (
							<Typography tag="p" className="gray-color">
								No location types selected.
							</Typography>
						)}
						{!locationTypes ||
							(locationTypes.length >= 1 && (
								<>
									{locationTypes?.includes('virtual') && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'camera']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													Virtual
												</Typography>
												<TextArea id="virtualLocationNotes" label="Location Notes" placeholder="Write a message..." error={errors?.virtualLocationNotes} {...register('virtualLocationNotes')} />
											</div>
										</div>
									)}
									{locationTypes?.includes('inperson') && (
										<div className="step__location">
											<div className="step__location-icon-wrapper">
												<FontAwesomeIcon icon={['fal', 'building']} size="lg" />
											</div>
											<div className="step__location-fields-wrapper">
												<Typography tag="h4" weight="bold">
													In Person
												</Typography>
												<TextInput id="inPersonLocationAddress" label="Address" placeholder="Address" error={errors?.inPersonLocationAddress?.addressLine1} {...register('inPersonLocationAddress.addressLine1', { required: required('Address') })} />
												<div className="step__location-field-row">
													<TextInput id="inpersonCity" label="City" placeholder="City" error={errors?.inPersonLocationAddress?.city} {...register('inPersonLocationAddress.city', { required: required('City') })} />
													<Controller
														name="inPersonLocationAddress.state"
														control={control}
														rules={{ required: required('State') }}
														render={({ field: { onChange, value } }) => (
															<Select
																getOptionLabel={(option) => option.abbreviation}
																getOptionValue={(option) => option.abbreviation}
																label="State"
																placeholder="State"
																id="state"
																error={errors?.inPersonLocationAddress?.state}
																options={STATE_OPTIONS}
																value={STATE_OPTIONS.find((c) => c.abbreviation === value) || ''}
																onChange={(val) => onChange(val.abbreviation)}
															/>
														)}
													/>
													<TextInput id="inpersonZipcode" label="Zipcode" placeholder="Zipcode" error={errors?.inPersonLocationAddress?.postalCode} {...register('inPersonLocationAddress.postalCode', { required: required('Zipcode') })} />
												</div>
												<TextArea id="inPersonNotes" label="Location Notes" placeholder="Write a message..." error={errors?.inPersonNotes} {...register('inPersonNotes')} />
											</div>
										</div>
									)}
								</>
							))}
					</S.SelectedLocations>
				</S.FieldsWrapper>
				<S.ButtonsWrapper>
					<Button type="submit">
						<Typography weight="bold" variation="button-medium">
							{isSubmitting ? 'Loading' : 'Save'}
						</Typography>
					</Button>
				</S.ButtonsWrapper>
			</S.Form>
		</ReactModal>
	);
});

LocationAltEditModal.displayName = 'LocationAltEditModal';

export const LocationCard = ({ className, role }) => {
	const {
		state: { resource },
	} = useContext(DirectoryResourceDetailsContext);
	const user = useSelector((state) => state.user.value);
	const modalRef = useRef(null);

	const handleEditClick = () => {
		modalRef.current.open();
	};

	const isOnlyVirtual = useMemo(() => {
		return resource.locationTypes.includes('virtual') && resource.locationTypes.length === 1;
	}, [resource]);

	return (
		<Card
			title="Location"
			className={className}
			transparentHeaderBorder
			actions={(role != null && resource.business != null && resource.business.id === user?.business?.id) || role === ROLES.ADMIN || role === ROLES.SUPER ? [{ id: 1, label: 'Edit', size: 'small', variant: 'outline', onClick: handleEditClick, icon: { source: ['fal', 'edit'] } }] : []}
		>
			<S.Wrapper>
				<div className="location-info">
					{resource.locationTypes.includes('virtual') && (
						<div className="location-info__element">
							<div className="location-info__icon-wrapper">
								<FontAwesomeIcon icon={['fal', 'camera']} />
							</div>
							<div className="location-info__info-wrapper">
								<div className="location-info__heading-wrapper">
									<Typography className="location-info__heading-wrapper__title" tag="h5" weight="bold">
										Virtual
									</Typography>
									<Typography className="location-info__heading-wrapper__subtitle" tag="p" variation="2">
										Meet your host virtually.
									</Typography>
								</div>
							</div>
						</div>
					)}
					{resource.locationTypes.includes('student') && (
						<div className="location-info__element">
							<div className="location-info__icon-wrapper">
								<FontAwesomeIcon icon={['fal', 'users']} />
							</div>
							<div className="location-info__info-wrapper">
								<div className="location-info__heading-wrapper">
									<Typography className="location-info__heading-wrapper__title" tag="h5" weight="bold">
										Student Location
									</Typography>
									<Typography className="location-info__heading-wrapper__subtitle" tag="p" variation="2">
										Have the host travel to you.
									</Typography>
									<div className="location-info__detail-wrapper">
										<Typography tag="h6" weight="semibold" className="location-info__detail-wrapper__title">
											Maximum Travel Distance
										</Typography>
										<Typography tag="p">{resource.studentMaxTravelDistance > 1 ? `${resource.studentMaxTravelDistance} miles` : '1 mile'}</Typography>
									</div>
								</div>
							</div>
						</div>
					)}
					{resource.locationTypes.includes('worksite') && (
						<div className="location-info__element">
							<div className="location-info__icon-wrapper">
								<FontAwesomeIcon icon={['fal', 'building']} />
							</div>
							<div className="location-info__info-wrapper">
								<div className="location-info__heading-wrapper">
									<Typography className="location-info__heading-wrapper__title" tag="h5" weight="bold">
										Worksite Location
									</Typography>
									<Typography className="location-info__heading-wrapper__subtitle" tag="p" variation="2">
										Travel to the business location.
									</Typography>
									{resource.worksiteLocations.length > 0 && (
										<div className="location-info__detail-wrapper">
											<Typography tag="h6" weight="semibold" className="location-info__detail-wrapper__title">
												Worksites
											</Typography>

											{resource.worksiteLocations.map((worksite, index) => (
												<div key={worksite.location.id}>
													<Typography tag="p">
														<span className="location-info__detail-wrapper__count">{index + 1}.</span>
														{worksite.location.name}
													</Typography>
													<Typography tag="p" variation="2" className="location-info__detail-wrapper__address">
														{buildAddressString(worksite.location.address)}
													</Typography>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
						</div>
					)}
					{resource.locationTypes.includes('inperson') && (
						<div className="location-info__element">
							<div className="location-info__icon-wrapper">
								<FontAwesomeIcon icon={['fal', 'building']} />
							</div>
							<div className="location-info__info-wrapper">
								<div className="location-info__heading-wrapper">
									<Typography className="location-info__heading-wrapper__title" tag="h5" weight="bold">
										In Person
									</Typography>
									<Typography className="location-info__heading-wrapper__subtitle" tag="p" variation="2">
										Travel to the Event Location
									</Typography>
									<div className="location-info__detail-wrapper">
										<Typography tag="h6" weight="semibold" className="location-info__detail-wrapper__title">
											Event Location
										</Typography>
										<div>
											<Typography tag="p">
												<span className="location-info__detail-wrapper__count">1.</span>
												Event Venue
											</Typography>
											<Typography tag="p" variation="2" className="location-info__detail-wrapper__address">
												{buildAddressString(resource.inPersonLocationAddress)}
											</Typography>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				{!isOnlyVirtual && (
					<div className="location-map">
						<div className="location-map__wrapper">
							<Map withMarkerIndex={true} role={role} enableGeolocationFallback={!role} markers={resource.locationTypes.includes('inperson') ? [{ ...resource }] : resource?.worksiteLocations?.map((item) => item.location)} />
						</div>
					</div>
				)}
			</S.Wrapper>
			{resource?.type === 'event' ? <LocationAltEditModal ref={modalRef} /> : <LocationEditModal ref={modalRef} />}
		</Card>
	);
};

LocationCard.propTypes = {
	className: PropTypes.string,
	role: PropTypes.string,
};
