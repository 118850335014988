import React from 'react';
import PropTypes from 'prop-types';

import { buildAddressString } from '../../../../utils/location-methods';
import { Card, Map, Typography } from '../../../../components';
import * as S from './LocationCard.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const LocationCard = ({ role, className, booking: { resource } }) => {
	const isOnlyVirtual = useMemo(() => {
		return resource.locationTypes.includes('virtual') && resource.locationTypes.length === 1;
	}, [resource]);

	return (
		<Card title="Location" className={className} transparentHeaderBorder>
			<S.Wrapper>
				<div className="location-info">
					{resource.locationTypes.includes('virtual') && (
						<div className="location-info__element">
							<div className="location-info__icon-wrapper">
								<FontAwesomeIcon icon={['fal', 'camera']} />
							</div>
							<div className="location-info__info-wrapper">
								<div className="location-info__heading-wrapper">
									<Typography className="location-info__heading-wrapper__title" tag="h5" weight="bold">
										Virtual
									</Typography>
									<Typography className="location-info__heading-wrapper__subtitle" tag="p" variation="2">
										Meet your host virtually.
									</Typography>
								</div>
							</div>
						</div>
					)}
					{resource.locationTypes.includes('student') && (
						<div className="location-info__element">
							<div className="location-info__icon-wrapper">
								<FontAwesomeIcon icon={['fal', 'users']} />
							</div>
							<div className="location-info__info-wrapper">
								<div className="location-info__heading-wrapper">
									<Typography className="location-info__heading-wrapper__title" tag="h5" weight="bold">
										Student Location
									</Typography>
									<Typography className="location-info__heading-wrapper__subtitle" tag="p" variation="2">
										Have the host travel to you.
									</Typography>
									<div className="location-info__detail-wrapper">
										<Typography tag="h6" weight="semibold" className="location-info__detail-wrapper__title">
											Maximum Travel Distance
										</Typography>
										<Typography tag="p">{resource.studentMaxTravelDistance > 1 ? `${resource.studentMaxTravelDistance} miles` : '1 mile'}</Typography>
									</div>
								</div>
							</div>
						</div>
					)}
					{resource.locationTypes.includes('worksite') && (
						<div className="location-info__element">
							<div className="location-info__icon-wrapper">
								<FontAwesomeIcon icon={['fal', 'building']} />
							</div>
							<div className="location-info__info-wrapper">
								<div className="location-info__heading-wrapper">
									<Typography className="location-info__heading-wrapper__title" tag="h5" weight="bold">
										Worksite Location
									</Typography>
									<Typography className="location-info__heading-wrapper__subtitle" tag="p" variation="2">
										Travel to the business location.
									</Typography>
									{resource.worksiteLocations.length > 0 && (
										<div className="location-info__detail-wrapper">
											<Typography tag="h6" weight="semibold" className="location-info__detail-wrapper__title">
												Worksites
											</Typography>

											{resource.worksiteLocations.map((worksite, index) => (
												<div key={worksite.location.id}>
													<Typography tag="p">
														<span className="location-info__detail-wrapper__count">{index + 1}.</span>
														{worksite.location.name}
													</Typography>
													<Typography tag="p" variation="2" className="location-info__detail-wrapper__address">
														{buildAddressString(worksite.location.address)}
													</Typography>
												</div>
											))}
										</div>
									)}
								</div>
							</div>
						</div>
					)}
					{resource.locationTypes.includes('inperson') && (
						<div className="location-info__element">
							<div className="location-info__icon-wrapper">
								<FontAwesomeIcon icon={['fal', 'building']} />
							</div>
							<div className="location-info__info-wrapper">
								<div className="location-info__heading-wrapper">
									<Typography className="location-info__heading-wrapper__title" tag="h5" weight="bold">
										In Person
									</Typography>
									<Typography className="location-info__heading-wrapper__subtitle" tag="p" variation="2">
										Travel to the Event Location
									</Typography>
									<div className="location-info__detail-wrapper">
										<Typography tag="h6" weight="semibold" className="location-info__detail-wrapper__title">
											Event Location
										</Typography>
										<div>
											<Typography tag="p">
												<span className="location-info__detail-wrapper__count">1.</span>
												Event Venue
											</Typography>
											<Typography tag="p" variation="2" className="location-info__detail-wrapper__address">
												{buildAddressString(resource.inPersonLocationAddress)}
											</Typography>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				{!isOnlyVirtual && (
					<div className="location-map">
						<div className="location-map__wrapper">
							<Map withMarkerIndex={true} role={role} enableGeolocationFallback={false} markers={resource.locationTypes.includes('inperson') ? [{ ...resource }] : resource?.worksiteLocations?.map((item) => item.location)} />
						</div>
					</div>
				)}
			</S.Wrapper>
		</Card>
	);
};

LocationCard.propTypes = {
	className: PropTypes.string,
	booking: PropTypes.shape(),
	role: PropTypes.string,
};
